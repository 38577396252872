import { JsonConvert, ValueCheckingMode, OperationMode } from 'json2typescript';

import { Action } from 'redux';
import {
  raiseError,
  requestVenueDetails,
  receiveVenueDetails,
  requestEmailSignUp,
  receiveEmailSignUp,
} from 'store/actions';

import { RootState } from 'store';
import { ThunkAction } from 'redux-thunk';
import Api from 'api/api';
import { rollbar } from 'api/api';
import { Venue } from 'types/Venue';

const jsonConvert: JsonConvert = new JsonConvert();
jsonConvert.operationMode =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? OperationMode.ENABLE
    : OperationMode.ENABLE;
jsonConvert.ignorePrimitiveChecks = false;
jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

export const API = new Api(
  process.env.REACT_APP_API_PROTOCOL as string,
  process.env.REACT_APP_API_URL as string,
  +(process.env.REACT_APP_API_PORT as string)
);

export const thunkGetVenueDetails = (
  venueId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch(requestVenueDetails(venueId));
  try {
    const response = await API.getVenueDetails(venueId);

    if (response.status === 200) {
      //do nothing on a 304. it will load from local storage
      const venue = jsonConvert.deserializeObject(response.data, Venue);
      dispatch(receiveVenueDetails(venue));
    }
  } catch (ex) {
    if (ex) {
      rollbar.error(
        'An error occurred while retrieving the venue details from api',
        ex
      );
      dispatch(raiseError());
    }
  }
};

export const thunkEmailSignUp = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  const state = getState();
  dispatch(requestEmailSignUp());
  try {
    const response = await API.emailSignUp(
      state.digitalmenu.venue.details.id,
      state.digitalmenu.emailSignUp.email
    );
    if (response.status === 200) {
      dispatch(receiveEmailSignUp());
    }
  } catch (ex) {
    if (ex) {
      rollbar.error(
        'An error occurred while retrieving the email sign up items from api',
        ex
      );
      dispatch(raiseError());
    }
  }
};

export const thunkRatingSubmission = (
  rating: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const state = getState();

  try {
    await API.ratingSubmit(state.digitalmenu.venue.details.id, rating);
  } catch (ex) {
    if (ex) {
      rollbar.error(
        'An error occurred while retrieving the email sign up items from api',
        ex
      );
      dispatch(raiseError());
    }
  }
};
