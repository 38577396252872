import React, { useEffect } from 'react';
import CSS from 'csstype';
import { Typography } from '@barpay/barpay-design-system';

const { H3 } = Typography;

export interface AdvertisementProps {
  show: boolean;
  adClientId: string;
  adSlotId: string;
  primary: boolean;
}

function Advertisement(props: AdvertisementProps) {
  useEffect(() => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = '(adsbygoogle = window.adsbygoogle || []).push({});';
    document.head.appendChild(s);

    return () => {
      document.head.removeChild(s);
    };
  }, []);

  const adStyle: CSS.Properties = {
    display: 'block',
  };

  const devAdStyle: CSS.Properties = {
    display: 'block',
    border: '1px solid #f00',
  };

  let headerText = <H3 fontWeight={'normal'}>Menu Sponsored By:</H3>;

  if (!props.primary) {
    headerText = (
      <H3>
        This free contactless menu is made possible thanks to advertisers like:{' '}
      </H3>
    );
  }

  return !props.show ? (
    <React.Fragment></React.Fragment>
  ) : (
    <React.Fragment>
      {headerText}
      <script
        async
        src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      <ins
        className="adsbygoogle"
        style={
          process.env.REACT_APP_ENVIRONMENT === 'development'
            ? devAdStyle
            : adStyle
        }
        data-ad-client={props.adClientId}
        data-adtest="on"
        data-ad-slot={props.adSlotId}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </React.Fragment>
  );
}

export default Advertisement;
