import React, { useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingPage from 'pages/LoadingPage';
import VenueLogo from 'components/VenueLogo';
import MenuSelector from 'components/MenuSelector';
import RatingSelector from 'components/RatingSelector';
import EmailSignUp from 'components/EmailSignUp';
import Advertisement from 'components/Advertisement';
import SocialLinks from 'components/SocialLinks';
import { RootState } from 'store';
import { thunkGetVenueDetails } from 'store/thunks';

const { H2, H4 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Header = styled.header`
  width: 100%;
  text-align: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainAdvertisement = styled.main`
  text-align: center;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Footer = styled.footer`
  width: 100%;
  background-color: #323e48;
  height: 60px;
  text-align: center;
  a {
    font-size: 16px;
    color: #7dcbc4;
    font-family: avenirmedium;
    font-weight: normal;
    text-decoration: none;
  }
`;

const MainAdvertisementSpacer = styled.div`
  height: 250px;
`;

function Home() {
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isLoading = useSelector(
    (state: RootState) =>
      state.digitalmenu.venue.isFetching ||
      state.digitalmenu.emailSignUp.isFetching
  );
  const showMenuAdvertisement = useSelector(
    (state: RootState) => state.digitalmenu.advertisementShow
  );
  const menuAdvertisementTimer = useSelector(
    (state: RootState) => state.digitalmenu.advertisementTimer
  );
  const venue = useSelector(
    (state: RootState) => state.digitalmenu.venue.details
  );

  const { id } = useParams();

  useEffect(() => {
    dispatch(thunkGetVenueDetails(id));
  }, [dispatch, id]);

  useLayoutEffect(() => {
    if (scrollRef.current) {
      const rootElement = document.querySelector<HTMLElement>('#pageContainer');
      const rect = scrollRef.current.getBoundingClientRect();

      setTimeout(() => {
        rootElement?.scrollTo({
          top: rect.top,
          left: 0,
          behavior: 'smooth',
        });
      }, 50); //set timeout is needed because the content is dynamic and the pixels don't exist yet
    }
  }, [showMenuAdvertisement]);

  let loading = <LoadingPage show={isLoading} />;

  let secondaryAdvertisement = <div />;

  if (showMenuAdvertisement) {
    secondaryAdvertisement = (
      <React.Fragment>
        <MainAdvertisementSpacer>
          <div style={{ visibility: 'hidden', height: '250px' }}>
            Fillter Content
          </div>
        </MainAdvertisementSpacer>
        <MainAdvertisement ref={scrollRef}>
          <Advertisement
            show={venue.showAds}
            adClientId={'ca-pub-1537973025808555'}
            adSlotId={'6861061907'}
            primary={false}
          />
          <H4>Menu will load in {menuAdvertisementTimer} seconds</H4>
        </MainAdvertisement>
      </React.Fragment>
    );
  }

  const container = (
    <PageContainer id={'pageContainer'}>
      {isLoading && loading}
      <Header>
        <VenueLogo logoUrl={venue.logo}></VenueLogo>
        <H2>Welcome To {venue.name}</H2>
      </Header>
      <Main>
        <MenuSelector
          menus={venue.menus}
          showAds={venue.showAds}
        ></MenuSelector>
        <Advertisement
          show={venue.showAds}
          adClientId={'ca-pub-1537973025808555'}
          adSlotId={'4254068105'}
          primary={true}
        />
        {venue.showSocial && (
          <SocialLinks
            facebookUrl={venue.facebookUrl}
            instagramUrl={venue.instagramUrl}
            twitterUrl={venue.twitterUrl}
          />
        )}
        {venue.collectRatings && <RatingSelector />}
        {venue.collectInfo && <EmailSignUp />}
      </Main>
      {secondaryAdvertisement}
      <Footer id={'digitalMenuFooter'}>
        <H4 fontWeight={'normal'} color={'#7DCBC4'} margin={'20px;'}>
          <a href={'https://www.barpay.com'}>Powered by Barpay</a>
        </H4>
      </Footer>
    </PageContainer>
  );

  return container;
}

export default Home;
