import { Venue } from 'types/Venue';

export const ERROR_UNKNOWN = 'ERROR_UNKNOWN';
export type ERROR = string | undefined;

export const REQUEST_VENUE_DETAILS = 'REQUEST_VENUE_DETAILS';
export const RECEIVE_VENUE_DETAILS = 'RECEIVE_VENUE_DETAILS';
export const RAISE_ERROR = 'RAISE_ERROR';
export const REQUEST_EMAILSIGNUP = 'REQUEST_EMAILSIGNUP';
export const RECEIVE_EMAILSIGNUP = 'RECEIVE_EMAILSIGNUP';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const VIEW_MENU = 'VIEW_MENU';
export const VIEW_MENU_TIMER = 'VIEW_MENU_TIMER';
export const VIEW_MENU_COMPLETE = 'VIEW_MENU_COMPLETE';

export interface DigitalMenuState {
  venue: {
    isFetching: boolean;
    details: Venue;
  };
  emailSignUp: {
    isFetching: boolean;
    email: string;
    submitted: boolean;
  };
  advertisementShow: boolean;
  advertisementTimer: number;
  error: boolean;
}

interface RequestVenueDetailsAction {
  type: typeof REQUEST_VENUE_DETAILS;
  venueId: string;
}

interface ReceiveVenueDetailsAction {
  type: typeof RECEIVE_VENUE_DETAILS;
  venue: Venue;
}

interface RequestEmailSignUpAction {
  type: typeof REQUEST_EMAILSIGNUP;
}

interface ReceiveEmailSignUpAction {
  type: typeof RECEIVE_EMAILSIGNUP;
}

interface RaiseErrorAction {
  type: typeof RAISE_ERROR;
}

interface ViewMenuAction {
  type: typeof VIEW_MENU;
}

interface ViewMenuTimerAction {
  type: typeof VIEW_MENU_TIMER;
}

interface ViewMenuCompleteAction {
  type: typeof VIEW_MENU_COMPLETE;
}

interface EditEmailAction {
  type: typeof EDIT_EMAIL;
  email: string;
}

export type DigitalMenuActionTypes =
  | RequestVenueDetailsAction
  | ReceiveVenueDetailsAction
  | RaiseErrorAction
  | RequestEmailSignUpAction
  | ReceiveEmailSignUpAction
  | EditEmailAction
  | ViewMenuAction
  | ViewMenuTimerAction
  | ViewMenuCompleteAction;
