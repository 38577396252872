import { useState } from 'react';
import { useSelector, DefaultRootState } from 'react-redux';

function UseValidateEmailField<TState = DefaultRootState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
  fieldName: string
): [boolean, string, (callback: Function) => void] {
  const field = useSelector(selector);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  let regexpEmail: RegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const validate = (callback: Function) => {
    if (!field || !regexpEmail.test((field as unknown) as string)) {
      setHasError(true);
      setErrorMessage(`You must enter a valid ${fieldName}`);
    } else {
      setHasError(false);
      setErrorMessage('');
      callback();
    }
  };

  return [hasError, errorMessage, validate];
}

export default UseValidateEmailField;
