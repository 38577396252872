import {
  DigitalMenuState,
  DigitalMenuActionTypes,
  REQUEST_VENUE_DETAILS,
  RECEIVE_VENUE_DETAILS,
  REQUEST_EMAILSIGNUP,
  RECEIVE_EMAILSIGNUP,
  RAISE_ERROR,
  EDIT_EMAIL,
  VIEW_MENU,
  VIEW_MENU_COMPLETE,
  VIEW_MENU_TIMER,
} from './types';
import { Venue } from 'types/Venue';

export const initialState: DigitalMenuState = {
  venue: {
    isFetching: false,
    details: new Venue(),
  },
  emailSignUp: {
    isFetching: false,
    email: '',
    submitted: false,
  },
  advertisementShow: false,
  advertisementTimer: 0,
  error: false,
};

export function digitalmenuReducer(
  state = initialState,
  action: DigitalMenuActionTypes
): DigitalMenuState {
  switch (action.type) {
    case REQUEST_VENUE_DETAILS:
      return {
        ...state,
        venue: {
          isFetching: true,
          details: new Venue(),
        },
      };
    case RECEIVE_VENUE_DETAILS:
      return {
        ...state,
        venue: {
          isFetching: false,
          details: action.venue,
        },
      };
    case REQUEST_EMAILSIGNUP:
      return {
        ...state,
        emailSignUp: {
          isFetching: true,
          email: state.emailSignUp.email,
          submitted: false,
        },
      };
    case RECEIVE_EMAILSIGNUP:
      return {
        ...state,
        emailSignUp: {
          isFetching: false,
          email: '',
          submitted: true,
        },
      };
    case RAISE_ERROR:
      return {
        ...state,
        error: true,
      };
    case VIEW_MENU:
      return {
        ...state,
        advertisementShow: true,
        advertisementTimer: 10,
      };
    case VIEW_MENU_TIMER:
      return {
        ...state,
        advertisementTimer: state.advertisementTimer - 1,
      };
    case VIEW_MENU_COMPLETE:
      return {
        ...state,
        advertisementShow: false,
        advertisementTimer: 0,
      };
    case EDIT_EMAIL:
      return {
        ...state,
        emailSignUp: {
          isFetching: state.emailSignUp.isFetching,
          email: action.email,
          submitted: false,
        },
      };

    default:
      return state;
  }
}
