import {
  DigitalMenuActionTypes,
  REQUEST_VENUE_DETAILS,
  RECEIVE_VENUE_DETAILS,
  RAISE_ERROR,
  RECEIVE_EMAILSIGNUP,
  REQUEST_EMAILSIGNUP,
  EDIT_EMAIL,
  VIEW_MENU,
  VIEW_MENU_COMPLETE,
  VIEW_MENU_TIMER,
} from 'store/types';

import { Venue } from 'types/Venue';

export function requestVenueDetails(venueId: string): DigitalMenuActionTypes {
  return {
    type: REQUEST_VENUE_DETAILS,
    venueId: venueId,
  };
}

export function receiveVenueDetails(venue: Venue): DigitalMenuActionTypes {
  return {
    type: RECEIVE_VENUE_DETAILS,
    venue: venue,
  };
}

export function requestEmailSignUp(): DigitalMenuActionTypes {
  return {
    type: REQUEST_EMAILSIGNUP,
  };
}

export function receiveEmailSignUp(): DigitalMenuActionTypes {
  return {
    type: RECEIVE_EMAILSIGNUP,
  };
}

export function editEmail(email: string): DigitalMenuActionTypes {
  return {
    type: EDIT_EMAIL,
    email: email,
  };
}

export function raiseError(): DigitalMenuActionTypes {
  return {
    type: RAISE_ERROR,
  };
}

export function viewMenu(): DigitalMenuActionTypes {
  return {
    type: VIEW_MENU,
  };
}

export function viewMenuTimer(): DigitalMenuActionTypes {
  return {
    type: VIEW_MENU_TIMER,
  };
}

export function viewMenuComplete(): DigitalMenuActionTypes {
  return {
    type: VIEW_MENU_COMPLETE,
  };
}
