import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { GlobalStyle } from '@barpay/barpay-design-system';
import { ModalBackground } from '@barpay/barpay-design-system';
import { ModalProvider } from 'styled-react-modal';
import ErrorPage from 'pages/ErrorPage';
import Home from 'pages/Home';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RootState } from 'store';

import { useSelector } from 'react-redux';

const AppContainer = styled.div`
  height: 100%;
  max-width: 500px;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0.5rem 0 0 0;
  position: absolute;
  height: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
`;

function App() {
  const hasError = useSelector((state: RootState) => state.digitalmenu.error);
  let container = <ErrorPage />;
  if (!hasError) {
    container = (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route exact path="/digital-menu/:id" component={Home} />
            <Route path="*">
              <ErrorPage />
            </Route>
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ModalProvider backgroundComponent={ModalBackground}>
        <AppContainer>{container}</AppContainer>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;
