import React from 'react';
import styled from 'styled-components';
import { colors } from '@barpay/barpay-design-system';
import { fonts } from '@barpay/barpay-design-system';
import { Menu } from 'types/Menu';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { useDispatch } from 'react-redux';
import { viewMenu, viewMenuComplete, viewMenuTimer } from 'store/actions';

export interface MenuSelectorProps {
  menus: Array<Menu>;
  showAds: boolean;
}

const StyledDropdown = styled.select`
  display: block;
  font-size: 20px;
  font-family: ${fonts.main};
  color: ${colors.background};
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 1rem 0;
  border: 1px solid ${colors.primary};
  border-radius: 10px;
  box-shadow: none;
  -moz-appearance: menulist-button;
  -webkit-appearance: menulist-button;
  appearance: menulist-button;
  background-color: ${colors.primary};
  text-align: center;

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: ${colors.background};
    outline: none;
  }
`;

const StyledOption = styled.option`
  font-size: 30px;
`;

function MenuSelector(props: MenuSelectorProps) {
  const dispatch = useDispatch();
  let menuTimerCount = 0;

  const menuTimer = () => {
    dispatch(viewMenuTimer());
    menuTimerCount--;
    if (menuTimerCount > 0) setTimeout(() => menuTimer(), 1 * 1000);
  };

  const openMenu = (url: string, props: MenuSelectorProps) => {
    if (props.showAds) {
      menuTimerCount = 10;
      dispatch(viewMenu());
      setTimeout(() => menuTimer(), 1 * 1000);
      setTimeout(() => {
        dispatch(viewMenuComplete());
        window.location.href = url;
      }, 10 * 1000);
    } else {
      window.location.href = url;
    }
  };

  let container = (
    <Button
      label="No Menu Selected"
      color="white"
      styleType={ButtonStyleType.Primary}
    />
  );

  if (props.menus.length > 1) {
    const menus = [];

    menus.push(<StyledOption key={'default'}>Select Menu</StyledOption>);
    for (const value of props.menus) {
      const url = value.link.length > 1 ? value.link : value.asset;
      menus.push(
        <StyledOption key={value.name} value={url}>
          {value.name}
        </StyledOption>
      );
    }

    container = (
      <StyledDropdown
        id="select-menu"
        name="select-menu"
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void =>
          openMenu(ev.target.value, props)
        }
      >
        {menus}
      </StyledDropdown>
    );
  } else if (props.menus.length === 1) {
    let url = '';
    if (props.menus[0] !== null && props.menus[0]['link'] !== null) {
      url =
        props.menus[0].link.length > 1
          ? props.menus[0].link
          : props.menus[0].asset;
    }
    container = (
      <React.Fragment>
        <Button
          label="View Menu"
          padding="0.6em 1.4em 0.5em 0.8em"
          color="white"
          styleType={ButtonStyleType.Primary}
          onClick={() => openMenu(url, props)}
        />
      </React.Fragment>
    );
  }

  return container;
}

export default MenuSelector;
