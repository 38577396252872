import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import facebookImage from 'assets/images/facebook.png';
import twitterImage from 'assets/images/twitter.png';
import instagramImage from 'assets/images/instagram.png';

const { SmallLead } = Typography;

const SocialLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 1rem;
`;

const SocialLinksDiv = styled.div`
  display: flex;
`;

interface SocialMediaLogo {
  url: string;
}

const SocialMediaImage = styled.div<SocialMediaLogo>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  margin: 1rem;
  height: 50px;
  width: 50px;
`;

export interface SocialLinksProps {
  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;
}

function SocialLinks(props: SocialLinksProps) {
  let facebook = <div />;
  let instagram = <div />;
  let twitter = <div />;

  let content = <React.Fragment />;

  if (
    (props.facebookUrl && props.facebookUrl.length > 0) ||
    (props.instagramUrl && props.instagramUrl.length > 0) ||
    (props.twitterUrl && props.twitterUrl.length > 0)
  ) {
    if (props.facebookUrl && props.facebookUrl.length > 0) {
      facebook = (
        <a href={props.facebookUrl} target={'_blank'} rel="noopener noreferrer">
          <SocialMediaImage url={facebookImage} />
        </a>
      );
    }
    if (props.instagramUrl && props.instagramUrl.length > 0) {
      instagram = (
        <a
          href={props.instagramUrl}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          <SocialMediaImage url={instagramImage} />
        </a>
      );
    }
    if (props.twitterUrl && props.twitterUrl.length > 0) {
      twitter = (
        <a href={props.twitterUrl} target={'_blank'} rel="noopener noreferrer">
          <SocialMediaImage url={twitterImage} />
        </a>
      );
    }

    content = (
      <SocialLinksContainer>
        <SmallLead>Follow Us</SmallLead>
        <SocialLinksDiv>
          {facebook}
          {instagram}
          {twitter}
        </SocialLinksDiv>
      </SocialLinksContainer>
    );
  }
  return content;
}

export default SocialLinks;
