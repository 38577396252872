import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import barpayBadge from '@barpay/barpay-design-system/lib/assets/images/BarpayBadge.png';

const { H2, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  height: 20%;
  width: 100%;
  text-align: center;
`;

const Main = styled.main`
  height: 80%;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
`;

const BarpayBadge = styled.img`
  border-radius: 10%;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-top: 2rem;
`;

function ErrorPage() {
  return (
    <PageContainer>
      <Header>
        <BarpayBadge src={barpayBadge} alt="Barpay Badge" />
      </Header>
      <Main>
        <H2>Uhh ohh!</H2>
        <H5 fontWeight={400}>
          An error occurred. Please notify your server or a staff member.
        </H5>
      </Main>
    </PageContainer>
  );
}

export default ErrorPage;
