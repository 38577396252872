import React, { useState } from 'react';
import styled from 'styled-components';
import Rating from 'react-rating';
import { Typography } from '@barpay/barpay-design-system';
import { thunkRatingSubmission } from 'store/thunks';
import { useDispatch } from 'react-redux';

const { H4 } = Typography;

const RatingStyle = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.colors.background};
  font-size: 36px;
`;

function RatingSelector() {
  const dispatch = useDispatch();
  const [canRate, setCanRate] = useState(true);
  const [rating, setRating] = useState(0);
  const [ratingHeader, setRatingHeader] = useState(
    'Leave Us a Rating in 1 Tap!'
  );

  const submitRating = (nextValue: number) => {
    setCanRate(false);
    setRatingHeader('Thanks for the feedback!');
    setRating(nextValue);
    dispatch(thunkRatingSubmission(nextValue));
  };

  const emptyStar = (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="48px"
      height="48px"
      viewBox="0 0 24 24"
      aria-labelledby="starIconTitle"
      stroke="#ffbe3c"
      fill="none"
      color="#ffbe3c"
    >
      <polygon points="12 17.844 6.183 20.902 7.294 14.425 2.588 9.838 9.092 8.893 12 3 14.908 8.893 21.412 9.838 16.706 14.425 17.817 20.902" />{' '}
    </svg>
  );

  const fullStar = (
    <svg fill="#ffbe3c" color="#ffbe3c">
      <polygon points="42.8,19.7 29.8,17.8 24,6 18.2,17.8 5.2,19.7 14.6,28.8 12.4,41.8 24,35.7 35.6,41.8 33.4,28.8 " />
    </svg>
  );

  const changeRatingHeader = (nextValue: number) => {
    if (canRate) {
      switch (nextValue) {
        case 1:
          setRatingHeader('Bad');
          break;
        case 2:
          setRatingHeader('Not Great');
          break;
        case 3:
          setRatingHeader('Good');
          break;
        case 4:
          setRatingHeader('Great');
          break;
        case 5:
          setRatingHeader('Amazing!');
          break;
        default:
          setRatingHeader('Leave Us a Rating in 1 Tap!');
          break;
      }
    }
  };

  return (
    <RatingStyle>
      <H4 fontWeight="normal">{ratingHeader}</H4>
      <Rating
        initialRating={rating}
        start={0}
        stop={5}
        step={1}
        emptySymbol={emptyStar}
        fullSymbol={fullStar}
        onHover={changeRatingHeader}
        readonly={!canRate}
        onChange={submitRating}
      />
    </RatingStyle>
  );
}

export default RatingSelector;
