import React from 'react';
import styled from 'styled-components';

export interface VenueLogoProps {
  logoUrl: string;
}

const VenueLogoImage = styled.div`
  margin-bottom: 2rem;
`;

function VenueLogo(props: VenueLogoProps) {
  return (
    <VenueLogoImage>
      <img alt="Venue Logo" src={props.logoUrl} height={150} />
    </VenueLogoImage>
  );
}

export default VenueLogo;
