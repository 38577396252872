import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import { TextBox, TextBoxStyleType } from '@barpay/barpay-design-system';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { thunkEmailSignUp } from 'store/thunks';
import { RootState } from 'store';
import { editEmail } from 'store/actions';
import useValidateEmailField from 'hooks/useValidateEmailField';

const { H4, SmallParagraph } = Typography;

const EmailSignUpDiv = styled.div``;
const ButtonDiv = styled.div`
  width: 75%;
  margin: 0 auto;
`;

function EmailSignUp() {
  const dispatch = useDispatch();
  const email = useSelector(
    (state: RootState) => state.digitalmenu.emailSignUp.email
  );
  const submitted = useSelector(
    (state: RootState) => state.digitalmenu.emailSignUp.submitted
  );

  const [hasEmailError, emailErrorMessage, validate] = useValidateEmailField(
    (state: RootState) => state.digitalmenu.emailSignUp.email,
    'email'
  );

  let successMessage = (
    <SmallParagraph margin="0" color={colors.success}>
      Email added successfully!
    </SmallParagraph>
  );

  if (!submitted) {
    successMessage = <div />;
  }

  return (
    <EmailSignUpDiv>
      <H4 fontWeight={'normal'}>Get notified about our specials and events?</H4>
      <TextBox
        label="edit-email"
        styleType={TextBoxStyleType.Standard}
        placeholder={'Enter Email'}
        value={email}
        onChange={(val) => {
          dispatch(editEmail(val.target.value));
        }}
        onBlur={(val) => {
          dispatch(editEmail(val.target.value));
        }}
        hasError={hasEmailError}
      />
      {successMessage}
      <SmallParagraph margin="0" color={colors.error}>
        {emailErrorMessage}
      </SmallParagraph>
      <ButtonDiv>
        <Button
          label="Submit"
          color="white"
          padding="0.6em 1.4em 0.5em 0.8em"
          styleType={ButtonStyleType.Primary}
          onClick={() => {
            validate(() => dispatch(thunkEmailSignUp()));
          }}
        />
      </ButtonDiv>
    </EmailSignUpDiv>
  );
}

export default EmailSignUp;
