import { Menu } from 'types/Menu';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Venue')
export class Venue {
  @JsonProperty('id', String)
  id: string;
  @JsonProperty('name', String)
  name: string;
  @JsonProperty('logo', String)
  logo: string;
  @JsonProperty('show_ads', Boolean)
  showAds: boolean;
  @JsonProperty('show_social', Boolean)
  showSocial: boolean;
  @JsonProperty('order_and_pay', Boolean)
  orderAndPay: boolean;
  @JsonProperty('collect_info', Boolean)
  collectInfo: boolean;
  @JsonProperty('collect_ratings', Boolean)
  collectRatings: boolean;
  @JsonProperty('product', Number)
  product: number;
  @JsonProperty('facebook_url', String)
  facebookUrl: string;
  @JsonProperty('instagram_url', String)
  instagramUrl: string;
  @JsonProperty('twitter_url', String)
  twitterUrl: string;
  @JsonProperty('menus', [Menu])
  menus: Array<Menu>;

  constructor() {
    this.id = '';
    this.name = '';
    this.logo = '';
    this.showAds = true;
    this.showSocial = true;
    this.orderAndPay = false;
    this.collectInfo = true;
    this.collectRatings = true;
    this.product = 1;
    this.facebookUrl = '';
    this.instagramUrl = '';
    this.twitterUrl = '';
    this.menus = [];
  }
}
