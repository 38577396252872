import axios from 'axios';
import Rollbar from 'rollbar';

export const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

export default class Api {
  url: string;

  constructor(protocol: string, apiUrl: string, apiPort?: number) {
    this.url = `${protocol}://${apiUrl}${apiPort !== 0 ? ':' + apiPort : ''}`;
  }

  async getVenueDetails(venueId: string) {
    const path = `${this.url}/api/digital_menus/${venueId}`;
    return axios.get(path);
  }

  async emailSignUp(venueId: string, email: string) {
    const path = `${this.url}/api/venues/${venueId}/submit_email`;
    return axios.post(path, {
      email: email,
    });
  }

  async ratingSubmit(venueId: string, rating: number) {
    const path = `${this.url}/api/venues/${venueId}/submit_review`;
    return axios.post(path, {
      rating: rating,
    });
  }
}
