import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Menu')
export class Menu {
  @JsonProperty('name', String)
  name: string;
  @JsonProperty('asset', String)
  asset: string;
  @JsonProperty('position', Number)
  position: number;
  @JsonProperty('link', String)
  link: string;

  constructor() {
    this.name = '';
    this.asset = '';
    this.position = 0;
    this.link = '';
  }
}
